import React from "react"
import { Link } from "gatsby"

import Section from "../components/section"
import Container from "../components/container"
import SectionTitle from "../components/section-title"
import SectionText from "../components/section-text"

const NotFoundPage = () => (
  <div className="relative h-screen max-w-[1440px] mx-auto max-h-[1080px] bg-neutral-900">
    <Section className="h-full">
      <Container className="h-full">
        <div className="flex flex-col items-center justify-center h-full max-w-3xl mx-auto text-center">
          <SectionTitle isHero className="mb-6">
            Thank You!
          </SectionTitle>
          <SectionText className="mb-8 !text-primary-50">
            You form submission is successful. We will contact you soon.
          </SectionText>
          <Link
            to="/"
            className="px-4 py-3 font-medium text-center text-white rounded-lg text-body-md bg-primary-600"
          >
            Back to Home
          </Link>
        </div>
      </Container>
    </Section>
  </div>
)

export default NotFoundPage
